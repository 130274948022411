import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Label } from '@/components/ui/label';

const LOCAL_STORAGE_KEY = 'bitpoint_user';

const LoginPage = () => {
const [identifier, setIdentifier] = useState('');
const [password, setPassword] = useState('');
const [isProcessing, setIsProcessing] = useState(true);
const [error, setError] = useState('');
const { login, checkLocalSession } = useAuth();
const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
    // Check local session first
    if (checkLocalSession()) {
    const redirectPath = location.state?.from?.pathname || '/dashboard';
    navigate(redirectPath, { replace: true });
    }
    setIsProcessing(false);
}, [checkLocalSession, navigate, location.state?.from?.pathname]);

const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError('');

    try {
    const success = await login(identifier, password);
    if (success) {
        const redirectPath = location.state?.from?.pathname || '/dashboard';
        navigate(redirectPath, { replace: true });
    } else {
        setError('Invalid credentials');
    }
    } catch (err) {
    setError(typeof err === 'string' ? err : 'An error occurred during login');
    } finally {
    setIsProcessing(false);
    }
};

  // Show loading spinner only during initial session check
  if (isProcessing && !identifier && !password) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex">
      {/* Left side - Branding */}
      <div className="hidden lg:flex lg:w-1/2 bg-blue-600 text-white p-12 flex-col justify-between">
        <div className="space-y-8">
          <div className="text-4xl font-bold">
            <span className="text-6xl">*</span>
          </div>
          <div className="space-y-4">
            <h1 className="text-5xl font-bold">Hello<br />There! 👋</h1>
            <p className="text-xl opacity-90">
              We are happy to have you here.
            </p>
          </div>
        </div>
        <div className="text-sm opacity-70">
          © {new Date().getFullYear()} Bitpoint. All rights reserved.
        </div>
      </div>

      {/* Right side - Login form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <Card className="w-full max-w-md border-none shadow-none">
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold">Welcome Back!</h2>
              <p className="text-muted-foreground">
                Don't have an account? Contact your administrator to request access.
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <div className="space-y-2">
                <Label htmlFor="identifier">Username or Email</Label>
                <Input
                  id="identifier"
                  name="identifier"
                  type="text"
                  autoComplete="username email"
                  required
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                  className="w-full"
                  placeholder="Enter your username or email"
                  disabled={isProcessing}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full"
                  placeholder="Enter your password"
                  disabled={isProcessing}
                />
              </div>

              <Button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700"
                disabled={isProcessing}
              >
                {isProcessing ? 'Signing in...' : 'Login Now'}
              </Button>

              <div className="text-center text-sm">
                <a href="#" className="text-blue-600 hover:text-blue-700">
                  Forgot password?
                </a>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
