// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ROLES } from '../constants/auth';

const ProtectedRoute = ({ children, allowedRoles = [], requiredPermissions = [] }) => {
  const location = useLocation();
  const { user } = useAuth();

  // Debug logging
  console.group('ProtectedRoute Check');
  console.log('Path:', location.pathname);
  console.log('User:', user);
  console.log('Required Roles:', allowedRoles);
  console.log('Required Permissions:', requiredPermissions);

  if (!user) {
    console.warn('No user session found');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check if user is admin first - admins bypass all checks
  const isAdmin = user.roles.some(role => 
    ['Administrator', 'System Manager'].includes(role)
  );

  console.log('Is Admin:', isAdmin);

  if (isAdmin) {
    console.log('Admin access granted');
    return children;
  }

  // Role check
  if (allowedRoles.length > 0) {
    const hasValidRole = allowedRoles.some(role => user.roles.includes(role));
    console.log('Role Check:', { hasValidRole, userRoles: user.roles, allowedRoles });

    if (!hasValidRole) {
      console.warn('Role check failed');
      return <Navigate to="/dashboard" replace />;
    }
  }

  // Permission check - skip if no permissions required
  if (requiredPermissions.length > 0) {
    // For users with specific roles, we might want to bypass permission checks
    const isBitDefenderOwner = user.roles.includes(ROLES.BIT_OWNER);
    
    if (isBitDefenderOwner) {
      console.log('BITDefender Owner access granted');
      return children;
    }

    // Check actual permissions if needed
    const hasValidPermissions = requiredPermissions.every(([module, permission]) => {
      // Search through user permissions
      const modulePermission = user.permissions?.find(p => {
        // Try to match the module name in different formats
        return p.module === module || 
               p.parent === module || 
               p === module;
      });

      console.log('Permission Check for', module, permission, {
        found: !!modulePermission,
        modulePermission
      });

      return !!modulePermission;
    });

    console.log('Permission Check Result:', {
      hasValidPermissions,
      requiredPermissions,
      userPermissions: user.permissions
    });

    if (!hasValidPermissions) {
      console.warn('Permission check failed');
      return <Navigate to="/dashboard" replace />;
    }
  }

  console.groupEnd();
  return children;
};

export default ProtectedRoute;