import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Users, Key, Activity, DollarSign, Calendar } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, ComposedChart } from 'recharts';
import { useDashboard } from '../hooks/useDashboard';
import { useTransactionsModal } from '../hooks/useTransactionsModal';
import { DateRangePicker } from '../components/DateRangePicker';

// StatsCard subcomponent
const StatsCard = ({ title, value, icon: Icon, trend, className = '' }) => (
  <Card className={className}>
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <CardTitle className="text-sm font-medium">{title}</CardTitle>
      <Icon className="h-4 w-4 text-muted-foreground" />
    </CardHeader>
    <CardContent>
      <div className="text-2xl font-bold">{value}</div>
      {trend && <p className="text-xs text-muted-foreground">{trend}</p>}
    </CardContent>
  </Card>
);

const Dashboard = () => {
  const {
    loading,
    error,
    data = {},
    filters,
    userProfiles,
    updateFilters
  } = useDashboard();

  // Set default profile when userProfiles are loaded
  useEffect(() => {
    if (Array.isArray(userProfiles) && userProfiles.length > 0 && !filters.mediaProfile) {
      const defaultProfile = userProfiles[0];
      const profileId = defaultProfile.media_profile || defaultProfile.id || defaultProfile.name;
      if (profileId) {
        updateFilters({ mediaProfile: profileId });
      }
    }
  }, [userProfiles, filters.mediaProfile, updateFilters]);

  const noProfileSelected = !filters.mediaProfile && Array.isArray(userProfiles) && userProfiles.length === 0;

  if (noProfileSelected) {
    return (
      <div className="flex-1 p-8">
        <Card>
          <CardContent className="pt-6">
            <div className="flex flex-col items-center justify-center text-center p-8">
              <Users className="h-12 w-12 text-muted-foreground mb-4" />
              <h3 className="text-lg font-semibold mb-2">No Media Profile Found</h3>
              <p className="text-sm text-muted-foreground mb-4">
                You currently don't have any media profiles set up. Please contact your administrator to get started.
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Format currency helper
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-KE', {
      style: 'currency',
      currency: 'KES'
    }).format(value || 0);
  };

  // Prepare chart data with null checks
  const chartData = (data.daily_labels || []).map((label, index) => ({
    name: label,
    revenue: (data.daily_amounts || [])[index] || 0,
    transactions: (data.daily_counts || [])[index] || 0
  }));

  if (error) {
    return (
      <Card className="m-4 border-destructive">
        <CardContent className="pt-6">
          <p className="text-destructive">Error loading dashboard: {error}</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="flex-1 space-y-6 p-8">
      {/* Dashboard Header */}
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
          <p className="text-muted-foreground">
            Welcome back to your media profile analytics
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-blue-50 p-2 rounded-lg">
            <Calendar className="h-4 w-4 text-blue-500" />
            <span className="ml-2 text-sm text-blue-600">
              {new Date().toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>

      {/* Filters */}
      <Card>
        <CardContent className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Media Profile</label>
              <Select 
                value={filters.mediaProfile || ''} 
                onValueChange={(value) => updateFilters({ mediaProfile: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select profile" />
                </SelectTrigger>
                <SelectContent>
                  {Array.isArray(userProfiles) && userProfiles.map(profile => (
                    <SelectItem key={profile.name} value={profile.name}>
                      {profile.name || 'Unnamed Profile'}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            
            <div className="space-y-2">
              <label className="text-sm font-medium">Date Range</label>
              <DateRangePicker
                fromDate={filters.fromDate}
                toDate={filters.toDate}
                onChange={({ fromDate, toDate }) => updateFilters({ fromDate, toDate })}
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Status</label>
              <Select 
                value={filters.status || 'All'} 
                onValueChange={(value) => updateFilters({ status: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="All">All</SelectItem>
                  <SelectItem value="Active">Active</SelectItem>
                  <SelectItem value="Inactive">Inactive</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Stats Overview */}
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <StatsCard
          title="Total Revenue"
          value={formatCurrency(data.total_revshare)}
          icon={DollarSign}
          trend={`${formatCurrency(data.total_revenue)} paid out`}
        />
        <StatsCard
          title="Total Transactions"
          value={(data.transaction_count || 0).toLocaleString()}
          icon={Activity}
          trend={`${data.successful_purchases || 0} successful`}
        />
        <StatsCard
          title="Success Rate"
          value={`${(data.success_rate || 0).toFixed(1)}%`}
          icon={Key}
          trend="Completion rate"
        />
        <StatsCard
          title="Active Purchase Codes"
          value={(data.active_purchase_codes || 0).toLocaleString()}
          icon={Users}
          trend="Currently active"
        />
      </div>

      {/* Charts */}
      <div className="grid gap-4 md:grid-cols-7">
        <Card className="col-span-4">
          <CardHeader>
            <CardTitle>Transaction Trends</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                  <XAxis 
                    dataKey="name" 
                    className="text-sm text-muted-foreground"
                  />
                  <YAxis 
                    yAxisId="left"
                    className="text-sm text-muted-foreground"
                    label={{ value: 'Revenue', angle: -90, position: 'insideLeft' }}
                  />
                  <YAxis 
                    yAxisId="right" 
                    orientation="right"
                    className="text-sm text-muted-foreground"
                    label={{ value: 'Transactions', angle: 90, position: 'insideRight' }}
                  />
                  <Tooltip />
                  <Bar 
                    dataKey="revenue" 
                    fill="#2563eb" 
                    yAxisId="left"
                    name="Revenue"
                  />
                  <Line 
                    type="monotone" 
                    dataKey="transactions" 
                    stroke="#16a34a"
                    yAxisId="right"
                    name="Transactions"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="col-span-3">
          <CardHeader>
            <CardTitle>Purchase Code Analysis</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-8">
              {(data.purchase_code_stats || []).slice(0, 5).map((stat) => (
                <div key={stat.purchase_code} className="flex items-center">
                  <div className="space-y-1">
                    <p className="text-sm font-medium leading-none">
                      {stat.purchase_code}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {stat.transactions} transactions
                    </p>
                  </div>
                  <div className="ml-auto font-medium">
                    {formatCurrency(stat.revenue)}
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      {loading && (
        <div className="fixed inset-0 bg-background/50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
