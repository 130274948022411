// src/components/PublicRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const storedUser = localStorage.getItem('bitpoint_user');
  
  if (storedUser) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default PublicRoute;