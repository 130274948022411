import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableRow, TableHeader, TableBody, TableHead, TableCell } from '@/components/ui/table';
import { getLicenseList } from '../services/api';
import { Button } from '@/components/ui/button';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import { Loader2 } from 'lucide-react';

// Separate component for status badge to improve reusability
const StatusBadge = ({ status }) => {
  const statusStyles = {
    Active: 'bg-green-100 text-green-800',
    Expired: 'bg-red-100 text-red-800',
    'Expiring Soon': 'bg-yellow-100 text-yellow-800',
    default: 'bg-gray-100 text-gray-800'
  };

  return (
    <span
      className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${
        statusStyles[status] || statusStyles.default
      }`}
    >
      {status}
    </span>
  );
};

// Separate component for summary card to improve reusability
const SummaryCard = ({ title, value, textColor = 'text-gray-900' }) => (
  <Card>
    <CardContent className="text-center pt-6">
      <p className="text-sm text-gray-500">{title}</p>
      <p className={`text-3xl font-bold ${textColor}`}>{value.toLocaleString()}</p>
    </CardContent>
  </Card>
);

const LicenseManagement = () => {
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [summary, setSummary] = useState({
    totalLicenses: 0,
    active: 0,
    expiringSoon: 0,
    expired: 0,
  });

  const loadLicenses = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      setError(null);
      const response = await getLicenseList({ page, pageSize });

      if (response.success) {
        setLicenses(response.licenses);
        setPagination(response.pagination);
        setSummary({
          totalLicenses: response.pagination.total,
          active: response.summary?.active || 0,
          expiringSoon: response.summary?.expiringSoon || 0,
          expired: response.summary?.expired || 0,
        });
      } else {
        throw new Error(response?.message || 'Failed to load licenses');
      }
    } catch (err) {
      setError(err.message);
      // Reset data on error
      setLicenses([]);
      setSummary({
        totalLicenses: 0,
        active: 0,
        expiringSoon: 0,
        expired: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLicenses(pagination.page, pagination.pageSize);
  }, [pagination.page, pagination.pageSize]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({ ...prev, page: newPage }));
    }
  };

  const handleManageLicense = (license) => {
    // Implementation for managing license
    console.log('Managing license:', license);
  };

  // Calculate pagination range
  const getPaginationRange = () => {
    const delta = 2;
    const range = [];
    const { page, totalPages } = pagination;

    for (
      let i = Math.max(2, page - delta);
      i <= Math.min(totalPages - 1, page + delta);
      i++
    ) {
      range.push(i);
    }

    if (page - delta > 2) {
      range.unshift('...');
    }
    if (page + delta < totalPages - 1) {
      range.push('...');
    }

    range.unshift(1);
    if (totalPages > 1) {
      range.push(totalPages);
    }

    return range;
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">License Management</h1>
          <p className="text-gray-500">Manage and monitor license status</p>
        </div>
        <Button variant="default">Add New License</Button>
      </div>

      {/* Stats Overview - Matching Analytics layout */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <SummaryCard 
          title="Total Licenses" 
          value={summary.totalLicenses} 
        />
        <SummaryCard 
          title="Active" 
          value={summary.active} 
          textColor="text-green-600" 
        />
        <SummaryCard 
          title="Expiring Soon" 
          value={summary.expiringSoon} 
          textColor="text-yellow-600" 
        />
        <SummaryCard 
          title="Expired" 
          value={summary.expired} 
          textColor="text-red-600" 
        />
      </div>

      {/* Main Content Card */}
      <Card>
        <CardHeader>
          <CardTitle>License List</CardTitle>
        </CardHeader>
        <CardContent>
          {loading ? (
            <div className="flex justify-center items-center p-8">
              <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
            </div>
          ) : error ? (
            <div className="text-red-500 p-4 text-center bg-red-50 rounded-md">
              {error}
            </div>
          ) : (
            <div className="space-y-4">
              <div className="rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[200px]">License Key</TableHead>
                      <TableHead>Type</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Expiry Date</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {licenses.map((license) => (
                      <TableRow key={license.license_key}>
                        <TableCell className="font-medium">
                          {license.license_key}
                        </TableCell>
                        <TableCell>{license.type}</TableCell>
                        <TableCell>
                          <StatusBadge status={license.status} />
                        </TableCell>
                        <TableCell>
                          {new Date(license.expiry_date).toLocaleDateString()}
                        </TableCell>
                        <TableCell className="text-right">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleManageLicense(license)}
                          >
                            Manage
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() => handlePageChange(pagination.page - 1)}
                      className={pagination.page === 1 ? 'pointer-events-none opacity-50' : ''}
                    />
                  </PaginationItem>
                  
                  {getPaginationRange().map((pageNum, idx) => (
                    <PaginationItem key={idx}>
                      {pageNum === '...' ? (
                        <span className="px-4 py-2">...</span>
                      ) : (
                        <PaginationLink
                          isActive={pagination.page === pageNum}
                          onClick={() => handlePageChange(pageNum)}
                        >
                          {pageNum}
                        </PaginationLink>
                      )}
                    </PaginationItem>
                  ))}
                  
                  <PaginationItem>
                    <PaginationNext
                      onClick={() => handlePageChange(pagination.page + 1)}
                      className={pagination.page === pagination.totalPages ? 'pointer-events-none opacity-50' : ''}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default LicenseManagement;