import React, { createContext, useContext, useState, useCallback } from 'react';
import { authAPI } from '../services/api';
import { ROLES } from '../constants/auth';

const AuthContext = createContext(null);
const LOCAL_STORAGE_KEY = 'bitpoint_user';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    try {
      const stored = localStorage.getItem(LOCAL_STORAGE_KEY);
      return stored ? JSON.parse(stored) : null;
    } catch {
      return null;
    }
  });
  const [roles, setRoles] = useState(() => user?.roles || []);
  const [permissions, setPermissions] = useState(() => user?.permissions || []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const clearError = useCallback(() => setError(null), []);

  const clearSession = useCallback(() => {
    setUser(null);
    setRoles([]);
    setPermissions(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }, []);

  const login = useCallback(async (email, password) => {
    try {
      setLoading(true);
      clearError();

      const { success, profile } = await authAPI.login(email, password);
      
      if (success && profile) {
        // Store session data
        setUser(profile);
        setRoles(profile.roles);
        setPermissions(profile.permissions);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(profile));
        return true;
      }
      
      setError('Invalid credentials');
      return false;
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Login failed');
      return false;
    } finally {
      setLoading(false);
    }
  }, [clearError]);

  const logout = useCallback(async () => {
    try {
      setLoading(true);
      
      // Clear local session first
      clearSession();
      
      // Attempt to logout from server
      await authAPI.logout().catch(console.error);
      
      // Force navigation to login page
      window.location.href = '/login';
      
      return true;
    } catch (error) {
      console.error('Logout error:', error);
      setError('Logout failed');
      return false;
    } finally {
      setLoading(false);
    }
  }, [clearSession]);
  
  // Update checkLocalSession to be more strict
  const checkLocalSession = useCallback(() => {
    try {
      const stored = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (!stored) {
        clearSession();
        return false;
      }
  
      const profile = JSON.parse(stored);
      if (!profile || !profile.roles || !profile.roles.length) {
        clearSession();
        return false;
      }
  
      setUser(profile);
      setRoles(profile.roles);
      return true;
    } catch {
      clearSession();
      return false;
    }
  }, [clearSession]);
  

  const hasRole = useCallback((role) => roles.includes(role), [roles]);
  const isAdmin = useCallback(() => {
    return roles.some((role) => ['Administrator', 'System Manager'].includes(role));
  }, [roles]);

  // hasPermission function to check CRUD rights
  const hasPermission = useCallback(
    (parent, action) => {
        // Find permissions for the specified parent
        const permission = permissions.find((perm) => perm.parent === parent);
        if (!permission) {
        return false; // No permissions found for this parent
        }
        // Check the specific action (e.g., read, write, create, delete)
        return Boolean(permission[action]);
    },
    [permissions]
    );

  return (
    <AuthContext.Provider
      value={{
        user,
        roles,
        loading,
        error,
        login,
        logout,
        clearError,
        checkLocalSession,
        hasRole,
        hasPermission,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
