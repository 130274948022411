// src/pages/MediaProfiles.jsx
import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Table } from '@/components/ui/table';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FileVideo, DollarSign, Activity, Users } from 'lucide-react';

const MediaProfiles = () => {
  const [activeView, setActiveView] = useState('overview');
  
  // Mock data - replace with real API calls
  const performanceData = [
    { date: '2024-01', transactions: 65, revenue: 4200 },
    { date: '2024-02', transactions: 85, revenue: 5600 },
    { date: '2024-03', transactions: 76, revenue: 4900 }
  ];

  const profileColumns = [
    {
      accessorKey: 'name',
      header: 'Profile Name',
    },
    {
      accessorKey: 'purchaseCode',
      header: 'Purchase Code',
    },
    {
      accessorKey: 'transactions',
      header: 'Transactions',
    },
    {
      accessorKey: 'revenue',
      header: 'Revenue',
      cell: ({ row }) => `KES ${row.original.revenue.toLocaleString()}`
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <span className={`px-2 py-1 rounded-full text-xs ${
          row.original.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {row.original.status}
        </span>
      )
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">Media Profiles</h1>
          <p className="text-gray-500">Manage your media profiles and track performance</p>
        </div>
        <Button>Create Profile</Button>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Profiles</CardTitle>
            <FileVideo className="h-4 w-4 text-gray-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">12</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Monthly Revenue</CardTitle>
            <DollarSign className="h-4 w-4 text-gray-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">KES 45,678</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Active Users</CardTitle>
            <Users className="h-4 w-4 text-gray-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">890</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Success Rate</CardTitle>
            <Activity className="h-4 w-4 text-gray-500" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">94.2%</div>
          </CardContent>
        </Card>
      </div>

      {/* Main Content */}
      <Card>
        <CardHeader>
          <Tabs defaultValue="overview" className="w-full">
            <TabsList>
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="performance">Performance</TabsTrigger>
              <TabsTrigger value="settings">Settings</TabsTrigger>
            </TabsList>

            <TabsContent value="overview" className="space-y-4">
              <div className="h-[300px] mt-4">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={performanceData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Line yAxisId="left" type="monotone" dataKey="transactions" stroke="#2563eb" />
                    <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#16a34a" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              
              <Table 
                columns={profileColumns} 
                data={[
                  { name: 'Profile 1', purchaseCode: 'CODE123', transactions: 156, revenue: 12400, status: 'Active' },
                  { name: 'Profile 2', purchaseCode: 'CODE456', transactions: 98, revenue: 8900, status: 'Active' }
                ]} 
              />
            </TabsContent>
          </Tabs>
        </CardHeader>
      </Card>
    </div>
  );
};

export default MediaProfiles;