// src/constants/auth.js

export const ROLES = {
  MEDIA_USER: 'Media Profile User',
  MEDIA_ADMIN: 'Media Profile Admin',
  MEDIA_COMPANY_USER: 'Media Company User',
  MEDIA_COMPANY_ADMIN: 'Media Company Admin',
  BIT_OWNER: 'BITDefender Owners',
  ECL_BITPOINT: 'ECL Bitpoint',
  ECL_MEDIA: 'ECL Media',
  ECL_CUSTOMER: 'ECL Customer',
  SYSTEM_ADMINISTRATOR: 'Administrator'
};

export const MODULES = {
  MEDIA: 'Media Profile',
  LICENSE: 'License Management',
  SYSTEM: 'System Settings'
};

// Role-based permission mappings
export const ROLE_PERMISSIONS = {
  [ROLES.BIT_OWNER]: {
    [MODULES.LICENSE]: ['read', 'write', 'create', 'delete'],
  },
  [ROLES.SYSTEM_ADMINISTRATOR]: {
    [MODULES.LICENSE]: ['read', 'write', 'create', 'delete'],
    [MODULES.MEDIA]: ['read', 'write', 'create', 'delete'],
    [MODULES.SYSTEM]: ['read', 'write', 'create', 'delete']
  }
};

export const PERMISSIONS = {
  [MODULES.LICENSE]: {
    READ: 'read',
    WRITE: 'write',
    CREATE: 'create',
    DELETE: 'delete'
  },
  [MODULES.MEDIA]: {
    READ: 'read',
    WRITE: 'write',
    CREATE: 'create',
    DELETE: 'delete'
  },
  [MODULES.SYSTEM]: {
    READ: 'read',
    WRITE: 'write',
    CREATE: 'create',
    DELETE: 'delete'
  }
};

// Helper function to check roles with admin override
export const hasRole = (userRoles, requiredRoles) => {
  if (!userRoles || !requiredRoles) return false;
  
  // Check for admin role first
  if (userRoles.includes(ROLES.SYSTEM_ADMINISTRATOR)) return true;
  
  const requiredRolesArray = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];
  return requiredRolesArray.some(role => userRoles.includes(role));
};

// Helper function to check permissions with role-based override
export const hasPermission = (userRoles, userPermissions, module, permission) => {
  // Admin override
  if (userRoles.includes(ROLES.SYSTEM_ADMINISTRATOR)) return true;
  
  // BITDefender Owner override for license management
  if (module === MODULES.LICENSE && userRoles.includes(ROLES.BIT_OWNER)) return true;
  
  // Check actual permissions
  return userPermissions?.some(p => 
    (p.module === module || p.parent === module) && p[permission]
  ) ?? false;
};