import React from 'react';
import { addDays, format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

export function DateRangePicker({ fromDate, toDate, onChange, className }) {
  const [date, setDate] = React.useState({
    from: fromDate ? new Date(fromDate) : new Date(),
    to: toDate ? new Date(toDate) : addDays(new Date(), 30),
  });

  // Handle date selection
  const handleSelect = (selectedDate) => {
    setDate(selectedDate || { 
      from: new Date(), 
      to: addDays(new Date(), 30) 
    });
    
    if (selectedDate?.from) {
      onChange({
        fromDate: format(selectedDate.from, 'yyyy-MM-dd'),
        toDate: selectedDate.to ? format(selectedDate.to, 'yyyy-MM-dd') : format(addDays(selectedDate.from, 30), 'yyyy-MM-dd')
      });
    }
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSelect}
            numberOfMonths={2}
            disabled={(date) =>
              date > new Date() || date < new Date('2000-01-01')
            }
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}