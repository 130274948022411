// src/App.jsx
import { Outlet, BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import AdminLayout from './components/layout/AdminLayout';
import { ROLES, MODULES, PERMISSIONS } from './constants/auth';

// Pages
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import LicenseManagement from './pages/LicenseManagement';
import LicenseAnalytics from './pages/LicenseAnalytics';
import LicenseUpload from './pages/LicenseUpload';
import MediaProfiles from './pages/MediaProfiles';
import MediaPerformance from './pages/MediaPerformance';
import UserManagement from './pages/admin/UserManagement';
import ProfileManagement from './pages/admin/ProfileManagement';
import Settings from './pages/admin/Settings';

// Define route configurations
const ROUTE_CONFIG = {
  LOGIN: {
    path: '/login',
    element: <LoginPage />,
    public: true,
  },
  DASHBOARD: {
    path: '/dashboard',
    element: <Dashboard />,
    allowedRoles: [], // Accessible to all authenticated users
  },
  LICENSES: {
    path: '/licenses',
    element: <LicenseManagement />,
    allowedRoles: [ROLES.ADMIN, ROLES.BIT_OWNER],
    permissions: [[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].READ]],
  },
  LICENSE_ANALYTICS: {
    path: '/licenses/analytics',
    element: <LicenseAnalytics />,
    allowedRoles: [ROLES.ADMIN, ROLES.BIT_OWNER],
    permissions: [[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].READ]],
  },
  LICENSE_UPLOAD: {
    path: '/licenses/upload',
    element: <LicenseUpload />,
    allowedRoles: [ROLES.ADMIN, ROLES.BIT_OWNER],
    permissions: [[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].CREATE]],
  },
  MEDIA_PROFILES: {
    path: '/media/profiles',
    element: <MediaProfiles />,
    allowedRoles: [ROLES.ADMIN, ROLES.MEDIA_MANAGER],
    permissions: [[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].READ]],
  },
  MEDIA_PERFORMANCE: {
    path: '/media/performance',
    element: <MediaPerformance />,
    allowedRoles: [ROLES.ADMIN, ROLES.MEDIA_MANAGER],
    permissions: [[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].READ]],
  },
  ADMIN_USERS: {
    path: '/admin/users',
    element: <UserManagement />,
    allowedRoles: [ROLES.ADMIN],
  },
  ADMIN_PROFILES: {
    path: '/admin/profiles',
    element: <ProfileManagement />,
    allowedRoles: [ROLES.ADMIN, ROLES.MEDIA_MANAGER],
    permissions: [[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].WRITE]],
  },
  ADMIN_SETTINGS: {
    path: '/admin/settings',
    element: <Settings />,
    allowedRoles: [ROLES.ADMIN],
  },
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Public Route */}
          <Route 
            path="/login" 
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            } 
          />

          {/* Root route with ProtectedRoute and AdminLayout */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            {/* Dashboard - default route */}
            <Route 
              index 
              element={<Navigate to="/dashboard" replace />} 
            />
            
            <Route 
              path="dashboard" 
              element={<Dashboard />} 
            />

            {/* License Management Routes */}
            <Route
              path="licenses"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.BIT_OWNER, ROLES.SYSTEM_ADMINISTRATOR]}
                  requiredPermissions={[[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].READ]]}
                >
                  <LicenseManagement />
                </ProtectedRoute>
              }
            />

            <Route
              path="licenses/analytics"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.BIT_OWNER, ROLES.SYSTEM_ADMINISTRATOR]}
                  requiredPermissions={[[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].READ]]}
                >
                  <LicenseAnalytics />
                </ProtectedRoute>
              }
            />

            <Route
              path="licenses/upload"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.BIT_OWNER, ROLES.SYSTEM_ADMINISTRATOR]}
                  requiredPermissions={[[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].CREATE]]}
                >
                  <LicenseUpload />
                </ProtectedRoute>
              }
            />

            {/* Media Management Routes */}
            <Route
              path="media/profiles"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.MEDIA_MANAGER, ROLES.SYSTEM_ADMINISTRATOR]}
                  requiredPermissions={[[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].READ]]}
                >
                  <MediaProfiles />
                </ProtectedRoute>
              }
            />

            <Route
              path="media/performance"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.MEDIA_MANAGER, ROLES.SYSTEM_ADMINISTRATOR]}
                  requiredPermissions={[[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].READ]]}
                >
                  <MediaPerformance />
                </ProtectedRoute>
              }
            />

            {/* Admin Routes */}
            <Route
              path="admin/users"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.SYSTEM_ADMINISTRATOR]}
                >
                  <UserManagement />
                </ProtectedRoute>
              }
            />

            <Route
              path="admin/settings"
              element={
                <ProtectedRoute
                  allowedRoles={[ROLES.SYSTEM_ADMINISTRATOR]}
                >
                  <Settings />
                </ProtectedRoute>
              }
            />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;