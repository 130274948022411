import React, { useState, useEffect, useCallback  } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Progress } from '@/components/ui/progress';
import { X, Download, Upload, FileText, AlertCircle } from 'lucide-react';
import { uploadLicenseBatch, getUploadHistory, getCurrentUser } from '../services/api';
import { useDropzone } from 'react-dropzone';

const LicenseUpload = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadHistory, setUploadHistory] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    loadUploadHistory(); // Load the upload history when the component mounts
    fetchCurrentUser(); // Load the current logged-in user
  }, []);

  const fetchCurrentUser = async () => {
    const result = await getCurrentUser();
    if (result.success) {
      setCurrentUser(result.user);
    } else {
      console.error('Error fetching current user:', result.error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files?.[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setProgress(0);
    }
  };

  const handleUpload = async () => {
    if (!file) return;
    
    try {
      setUploading(true);
      const progressInterval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);
  
      const formData = new FormData();
      formData.append('file', file);
  
      const result = await uploadLicenseBatch(formData);
  
      clearInterval(progressInterval);
      setProgress(100);
  
      if (result.success) {
        setTimeout(() => {
          setFile(null);
          setFileName('');
          setProgress(0);
          setUploading(false);
          // Refresh the upload history after successful upload
          loadUploadHistory();
        }, 1000);
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      setProgress(0);
      setUploading(false);
    }
  };
  const loadUploadHistory = async () => {
    const result = await getUploadHistory();
    if (result.success) {
      setUploadHistory(result.uploads || []);
    } else {
      console.error('Error fetching upload history:', result.error);
      setUploadHistory([]);
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.[0]) {
      setFile(acceptedFiles[0]);
      setFileName(acceptedFiles[0].name);
      setProgress(0);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false
  });

  const removeFile = () => {
    setFile(null);
    setFileName('');
    setProgress(0);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">License Upload</h1>
          <p className="text-gray-500">Upload and manage license batches</p>
        </div>
        <Button variant="outline">
          <Download className="h-4 w-4 mr-2" />
          Download Template
        </Button>
      </div>

<Card>
      <CardHeader>
        <CardTitle>Upload New Licenses</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Please ensure your CSV file follows the required format: License Key, Duration, Type, Maximum Devices
          </AlertDescription>
        </Alert>

        <div className="space-y-4">
            {!file ? (
              <div
                {...getRootProps()}
                className={`
                  border-2 border-dashed rounded-lg p-8
                  text-center cursor-pointer transition-colors
                  ${isDragActive 
                    ? 'border-blue-500 bg-blue-50' 
                    : 'border-gray-300 hover:border-blue-500 hover:bg-gray-50'}
                `}
              >
                <input {...getInputProps()} />
                <Upload className="h-10 w-10 mx-auto mb-4 text-gray-400" />
                <div className="text-lg font-medium">Drag and drop your CSV file here</div>
                <div className="text-sm text-gray-500 mt-1">or</div>
                <Button variant="secondary" className="mt-2">
                  Browse Files
                </Button>
              </div>
            ) : (
              <div className="space-y-4">
                <div className="flex items-center justify-between p-3 border rounded-lg bg-gray-50">
                  <div className="flex items-center space-x-3">
                    <FileText className="h-5 w-5 text-blue-500" />
                    <div>
                      <div className="font-medium">{fileName}</div>
                      <div className="text-sm text-gray-500">
                        {(file.size / 1024).toFixed(1)} KB
                      </div>
                    </div>
                  </div>
                  {!uploading && (
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={removeFile}
                      className="text-gray-500 hover:text-red-500"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>

                {progress > 0 && (
                  <div className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span>Upload Progress</span>
                      <span>{progress}%</span>
                    </div>
                    <Progress value={progress} className="h-2" />
                  </div>
                )}

                <Button
                  onClick={handleUpload}
                  disabled={uploading}
                  className="w-full"
                >
                  <Upload className="h-4 w-4 mr-2" />
                  {uploading ? 'Uploading...' : 'Start Upload'}
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Upload History</CardTitle>
        </CardHeader>
        <CardContent>
          {uploadHistory && uploadHistory.length > 0 ? (
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>File Name</TableHead>
                    <TableHead>Uploaded By</TableHead>
                    <TableHead>Upload Date</TableHead>
                    <TableHead>Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {uploadHistory.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">
                        <div className="flex items-center gap-2">
                          <FileText className="h-4 w-4 text-gray-500" />
                          {row.csv_file.split('/').pop()}
                        </div>
                      </TableCell>
                      <TableCell>{row.uploaded_by}</TableCell>
                      <TableCell>{new Date(row.upload_date).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          row.status === 'Completed' 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {row.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <p className="text-center text-gray-500">No upload history found.</p>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default LicenseUpload;
