import axios from 'axios';

// Function to generate the basic authorization header
const generateAuthHeader = (apiKey, apiSecret) => {
  const credentials = `${apiKey}:${apiSecret}`;
  const encodedCredentials = btoa(credentials); // base64 encoding
  return `Basic ${encodedCredentials}`;
};

// Replace with your actual API Key and Secret
const apiKey = "195026a7a3dc994";
const apiSecret = "06107f998d77f1b";

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': generateAuthHeader(apiKey, apiSecret),
  },
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data || error.message)
);

// Function to extract roles from profile data
function extractRoles(profileData) {
  if (
    profileData?.message?.profile?.roles &&
    Array.isArray(profileData.message.profile.roles)
  ) {
    return profileData.message.profile.roles;
  }
  return [];
}

// Authentication API functions
export const authAPI = {
  login: async (email, password) => {
    try {
      const response = await api.post('/method/login', { usr: email, pwd: password });
      console.log(response);
      if (response.message === 'Logged In') {
        // Fetch profile immediately after successful login
        const loggedInUserResponse = await api.get('/method/frappe.auth.get_logged_user');
        const username = loggedInUserResponse?.message;

        if (username && username !== 'Guest') {
          const profileResponse = await api.post('/method/get_user_profile', { username });
          const profile = profileResponse?.message?.profile;
          const roles = extractRoles(profileResponse);

          if (profile && roles.length > 0) {
            return {
              success: true,
              profile: { ...profile, roles }
            };
          }
        }
      }
      return { success: false };
    } catch (error) {
      console.error('Login failed:', error);
      return { success: false, error };
    }
  },

  logout: async () => {
    try {
      await api.post('/method/logout');
      return true;
    } catch (error) {
      console.error('Logout failed:', error);
      return false;
    }
  },
};

// Function to get the current logged-in user
export const getCurrentUser = async () => {
  try {
    const response = await api.get('/method/frappe.auth.get_logged_user');
    console.log('Current User Response:', response);

    if (response.message && response.message !== 'Guest') {
      return { success: true, user: response.message };
    } else {
      return { success: false, error: 'No user is logged in' };
    }
  } catch (error) {
    console.error('Failed to fetch current user:', error);
    return { success: false, error: error.message };
  }
};

// Function to upload file and create a License Batch Upload
export const uploadLicenseBatch = async (formData) => {
  try {
    // Add doctype and docname to formData
    formData.append('doctype', 'License Batch Upload');
    formData.append('docname', 'new-license-batch');
    formData.append('is_private', 1);

    const fileUploadResponse = await api.post('/method/upload_file', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (!fileUploadResponse?.message?.file_url) {
      throw new Error('File upload failed');
    }

    const { success, user } = await getCurrentUser();
    if (!success) {
      throw new Error('Could not determine current user');
    }

    // Format date in MySQL format: YYYY-MM-DD HH:MM:SS
    const now = new Date();
    const formattedDate = now.getFullYear() + '-' + 
                         String(now.getMonth() + 1).padStart(2, '0') + '-' + 
                         String(now.getDate()).padStart(2, '0') + ' ' + 
                         String(now.getHours()).padStart(2, '0') + ':' + 
                         String(now.getMinutes()).padStart(2, '0') + ':' + 
                         String(now.getSeconds()).padStart(2, '0');

    const docTypeData = {
      csv_file: fileUploadResponse.message.file_url,
      upload_date: formattedDate,
      uploaded_by: user,
      status: 'Pending'
    };

    const response = await api.post('/resource/License Batch Upload', docTypeData);

    return {
      success: true,
      data: response.data
    };

  } catch (error) {
    console.error('Error in license batch upload:', error);
    return {
      success: false,
      error: error.message || 'Upload failed'
    };
  }
};

// Get License List
export const getLicenseList = async ({ page, pageSize, fromDate, toDate, status }) => {
  const requestData = {
    page,
    page_size: pageSize,
    from_date: fromDate,
    to_date: toDate,
    status: status || 'All',
  };

  try {
    const response = await api.post('/method/eclbitpoint.api.license.get_license_list', requestData);
    console.log('License List Response:', response);

    if (response?.message) {
      return response.message;
    } else {
      return { success: false, error: 'Invalid response from server' };
    }
  } catch (error) {
    console.error('Failed to fetch license list:', error);
    return { success: false, error: error.message };
  }
};

// Get Upload History
export const getUploadHistory = async () => {
  try {
    const response = await api.post('/method/get_upload_history');

    console.log('Response:', response);

    if (response && response.message) {
      const isSuccess = response.message.success;
      const uploads = response.message.uploads;

      if (isSuccess) {
        console.log("Uploads:", uploads);
        return { success: true, uploads };
      } else {
        console.error('Upload history fetching failed.');
        return { success: false, error: 'Failed to fetch upload history.' };
      }
    } else {
      return { success: false, error: 'Invalid response from server.' };
    }
  } catch (error) {
    console.error('Error fetching upload history:', error);
    return { success: false, error: error.message };
  }
};

export const getDashboardData = async (fromDate, toDate, status = 'All') => {
  try {
    const response = await api.post('/method/eclbitpoint.api.license.get_dashboard_data', {
      from_date: fromDate,
      to_date: toDate,
      status
    });

    if (response?.message) {
      return { success: true, data: response.message };
    }
    return { success: false, error: 'Invalid response from server' };
  } catch (error) {
    console.error('Dashboard data fetch error:', error);
    return { success: false, error: error.message || 'Failed to load dashboard data' };
  }
};

export default api;
