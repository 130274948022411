import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Loader2 } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { getDashboardData } from '../services/api';

const REVENUE_PER_LICENSE = 150; // 150 KES per license

const LicenseAnalytics = () => {
  const [period, setPeriod] = useState('30');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    stats: {
      total_licenses: 0,
      available_licenses: 0,
      assigned_licenses: 0,
      recent_assignments: 0,
      expiring_soon: 0,
      recent_revenue: 0,
      transaction_count: 0,
      activation_rate: 0
    },
    transaction_trends: [],
    distribution: {
      labels: [],
      data: []
    }
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - parseInt(period));
        
        const formattedToDate = toDate.toISOString().split('T')[0];
        const formattedFromDate = fromDate.toISOString().split('T')[0];

        const response = await getDashboardData(formattedFromDate, formattedToDate);
        
        if (!response.success) {
          throw new Error(response.error);
        }

        // Extract data from the correct response structure
        const message = response.data || {};
        const stats = message.stats || {};
        const trends = message.transaction_trends || {};

        // Process trend data from the actual structure
        const trendData = [];
        if (trends.labels && trends.data && trends.revenue) {
          for (let i = 0; i < trends.labels.length; i++) {
            trendData.push({
              date: trends.labels[i],
              value: trends.data[i] || 0,
              revenue: trends.revenue[i] || 0
            });
          }
        }

        // Calculate stats from the actual response structure
        const totalLicenses = parseInt(stats.total_licenses) || 0;
        const assignedLicenses = parseInt(stats.assigned_licenses) || 0;
        const availableLicenses = parseInt(stats.available_licenses) || 0;
        const recentAssignments = parseInt(stats.recent_assignments) || 0;
        const expiringCount = parseInt(stats.expiring_soon) || 0;
        const recentRevenue = parseInt(stats.recent_revenue) || 0;

        // Calculate activation rate
        const activationRate = totalLicenses > 0 ? 
          ((assignedLicenses / totalLicenses) * 100).toFixed(1) : '0';

        setData({
          stats: {
            total_licenses: totalLicenses,
            available_licenses: availableLicenses,
            assigned_licenses: assignedLicenses,
            recent_assignments: recentAssignments,
            expiring_soon: expiringCount,
            recent_revenue: recentRevenue,
            transaction_count: recentAssignments,
            activation_rate: activationRate
          },
          transaction_trends: trendData,
          distribution: {
            labels: message.distribution?.labels || [],
            data: message.distribution?.data || []
          }
        });

      } catch (error) {
        console.error('Dashboard data fetch error:', error);
        setError(error.message || 'Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [period]);

  const formatRevenue = (amount) => {
    if (typeof amount !== 'number' || isNaN(amount)) {
      return 'KES 0';
    }
    return `KES ${amount.toLocaleString()}`;
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-600 bg-red-50 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">License Analytics</h2>
        <Select value={period} onValueChange={setPeriod}>
          <SelectTrigger className="w-40">
            <SelectValue placeholder="Select period" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="7">Last 7 days</SelectItem>
            <SelectItem value="30">Last 30 days</SelectItem>
            <SelectItem value="90">Last 90 days</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Total Licenses</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {data.stats.total_licenses.toLocaleString()}
            </div>
            <p className="text-xs text-muted-foreground">
              Available: {data.stats.available_licenses.toLocaleString()}
            </p>
            <p className="text-xs text-green-600">
              Potential Revenue: {formatRevenue(data.stats.total_licenses * REVENUE_PER_LICENSE)}
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Active Licenses</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {data.stats.assigned_licenses.toLocaleString()}
            </div>
            <p className="text-xs text-green-600">
              Revenue: {formatRevenue(data.stats.assigned_licenses * REVENUE_PER_LICENSE)}
            </p>
            <p className="text-xs text-blue-600">
              Activation Rate: {data.stats.activation_rate}%
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Recent Activity</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatRevenue(data.stats.recent_revenue)}
            </div>
            <p className="text-xs text-green-600">
              {data.stats.transaction_count} transactions
            </p>
            <p className="text-xs text-blue-600">
              {data.stats.recent_assignments} new assignments
            </p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">License Status</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {data.stats.expiring_soon.toLocaleString()}
            </div>
            <p className="text-xs text-amber-600">Expiring in 30 days</p>
            <p className="text-xs text-blue-600">
              {data.stats.activation_rate}% utilization
            </p>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Transaction Trends</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data.transaction_trends}>
                <XAxis 
                  dataKey="date"
                  tickFormatter={(value) => {
                    const [day, month] = value.split('-');
                    return `${day}/${month}`;
                  }}
                />
                <YAxis 
                  yAxisId="left"
                  label={{ value: 'Transactions', angle: -90, position: 'insideLeft' }}
                />
                <YAxis 
                  yAxisId="right"
                  orientation="right"
                  label={{ value: 'Revenue (KES)', angle: 90, position: 'insideRight' }}
                />
                <Tooltip 
                  formatter={(value, name) => [
                    name === 'revenue' ? formatRevenue(value) : value,
                    name === 'revenue' ? 'Revenue' : 'Transactions'
                  ]}
                  labelFormatter={(value) => value}
                />
                <Line 
                  yAxisId="left"
                  type="monotone"
                  dataKey="value"
                  name="transactions"
                  stroke="#2563eb"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
                <Line 
                  yAxisId="right"
                  type="monotone"
                  dataKey="revenue"
                  name="revenue"
                  stroke="#16a34a"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LicenseAnalytics;