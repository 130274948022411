import React, { useState, createContext, useContext, useEffect } from 'react';
import { 
  Menu, 
  X,
  Home, 
  FileText, 
  BarChart2, 
  Users, 
  Settings, 
  Bell, 
  Search,
  UserCog,
  FileEdit,
  Shield,
  Key,
  FileVideo,
  Database,
  Activity,
  CreditCard
} from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ROLES, MODULES, PERMISSIONS } from '@/constants/auth'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

// Route configuration with permissions
const ROUTES = {
  DASHBOARD: {
    path: '/dashboard',
    permissions: []
  },
  LICENSES: {
    path: '/licenses',
    permissions: [[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].READ]],
    roles: [ROLES.BIT_OWNER, ROLES.ADMIN]
  },
  LICENSE_ANALYTICS: {
    path: '/licenses/analytics',
    permissions: [[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].READ]],
    roles: [ROLES.BIT_OWNER, ROLES.ADMIN]
  },
  LICENSE_UPLOAD: {
    path: '/licenses/upload',
    permissions: [[MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].CREATE]],
    roles: [ROLES.BIT_OWNER, ROLES.ADMIN]
  },
  PROFILES: {
    path: '/media/profiles',
    permissions: [[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].READ]],
    roles: [ROLES.MEDIA_MANAGER, ROLES.ADMIN]
  },
  MEDIA_PERFORMANCE: {
    path: '/media/performance',
    permissions: [[MODULES.MEDIA, PERMISSIONS[MODULES.MEDIA].READ]],
    roles: [ROLES.MEDIA_MANAGER, ROLES.ADMIN]
  },
  ADMIN_USERS: {
    path: '/admin/users',
    roles: [ROLES.ADMIN]
  },
  ADMIN_SETTINGS: {
    path: '/admin/settings',
    roles: [ROLES.ADMIN]
  }
};

const SidebarContext = createContext({
  sidebarOpen: false,
  setSidebarOpen: () => {},
  isMobile: false
});

// Notifications Bell Component
const NotificationsBell = ({ notifications }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="relative p-2 rounded-lg hover:bg-gray-100">
          <Bell className="h-5 w-5" />
          {notifications.length > 0 && (
            <Badge 
              variant="destructive" 
              className="absolute -top-1 -right-1 h-5 w-5 flex items-center justify-center p-0"
            >
              {notifications.length}
            </Badge>
          )}
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-80">
        <DropdownMenuLabel>Notifications</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {notifications.map((notification) => (
          <DropdownMenuItem 
            key={notification.id} 
            className="flex flex-col items-start gap-1 py-2"
          >
            <div className="font-medium">{notification.title}</div>
            <div className="text-sm text-gray-500">{notification.message}</div>
            <div className="text-xs text-gray-400">{notification.time}</div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// User Menu Component
const UserMenu = ({ user, isAdmin, handleLogout }) => {
  const navigate = useNavigate();
  
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex items-center gap-3">
          <Avatar className="h-8 w-8">
            <AvatarImage src={user?.avatar} alt={user?.first_name} />
            <AvatarFallback className="bg-blue-500 text-white">
              {user?.first_name?.charAt(0) || 'U'}
            </AvatarFallback>
          </Avatar>
          <div className="hidden md:flex flex-col items-start">
            <span className="text-sm font-medium">{user?.first_name}</span>
            <span className="text-xs text-gray-500">
              {isAdmin() ? 'Administrator' : user?.roles?.[0]}
            </span>
          </div>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onSelect={() => navigate('/profile')}>
          Profile Settings
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => navigate('/preferences')}>
          Preferences
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem 
          className="text-red-600 focus:text-red-600" 
          onSelect={handleLogout}
        >
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// Search Bar Component
const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <div className="relative hidden md:block">
    <Search 
      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
      size={18} 
    />
    <input
      type="text"
      placeholder="Search..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full md:w-64 pl-10 pr-4 py-2 text-sm rounded-lg border border-gray-200 
                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    />
  </div>
);

// Sidebar Item Component
const SidebarItem = ({ icon: Icon, title, to, onClick }) => {
  const location = useLocation();
  const { sidebarOpen, isMobile } = useContext(SidebarContext);
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      onClick={onClick}
      className={cn(
        "flex items-center gap-3 px-3 py-2 rounded-md transition-colors duration-200",
        isActive ? "bg-blue-50 text-blue-600" : "text-gray-600 hover:bg-gray-50",
        !sidebarOpen && !isMobile && "justify-center"
      )}
      title={!sidebarOpen && !isMobile ? title : undefined}
    >
      <Icon className="h-5 w-5 flex-shrink-0" />
      {(sidebarOpen || isMobile) && (
        <span className="text-sm font-medium truncate">{title}</span>
      )}
    </Link>
  );
};

const AdminLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { user, logout, hasRole, hasPermission, isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (!mobile) setSidebarOpen(true);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close sidebar on route change in mobile
  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  }, [location, isMobile]);

  // Sample notifications
  const notifications = [
    { id: 1, title: 'License Upload Complete', message: 'New batch processed successfully', time: '2m ago' },
    { id: 2, title: 'New User Registration', message: 'User account pending approval', time: '5m ago' }
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Get menu items based on user's roles and permissions
  const getMenuItems = () => {
    const items = [
      {
        type: 'item',
        icon: Home,
        title: "Dashboard",
        to: ROUTES.DASHBOARD.path
      }
    ];

    if (isAdmin() || hasRole(ROLES.BIT_OWNER)) {
      items.push({
        type: 'section',
        title: 'License Management',
        items: [
          {
            icon: Database,
            title: "License Pool",
            to: ROUTES.LICENSES.path,
          },
          {
            icon: Key,
            title: "Upload Licenses",
            to: ROUTES.LICENSE_UPLOAD.path,
            show: hasPermission(MODULES.LICENSE, PERMISSIONS[MODULES.LICENSE].CREATE)
          },
          {
            icon: BarChart2,
            title: "Analytics",
            to: ROUTES.LICENSE_ANALYTICS.path
          }
        ].filter(item => !item.show || item.show === true)
      });
    }

    if (isAdmin() || hasRole(ROLES.MEDIA_MANAGER)) {
      items.push({
        type: 'section',
        title: 'Media Management',
        items: [
          {
            icon: FileVideo,
            title: "Profiles",
            to: ROUTES.PROFILES.path
          },
          {
            icon: Activity,
            title: "Performance",
            to: ROUTES.MEDIA_PERFORMANCE.path
          }
        ]
      });
    }

    if (isAdmin()) {
      items.push({
        type: 'section',
        title: 'Administration',
        items: [
          {
            icon: UserCog,
            title: "User Management",
            to: ROUTES.ADMIN_USERS.path
          },
          {
            icon: Shield,
            title: "System Settings",
            to: ROUTES.ADMIN_SETTINGS.path
          }
        ]
      });
    }

    return items;
  };

  return (
    <SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen, isMobile }}>
      <div className="min-h-screen bg-gray-50">
        {/* Mobile Overlay */}
        {isMobile && sidebarOpen && (
          <div 
            className="fixed inset-0 bg-black/50 z-40 lg:hidden"
            onClick={() => setSidebarOpen(false)}
          />
        )}

        {/* Top Navigation */}
        <header className="sticky top-0 z-40 bg-white border-b border-gray-200">
          <div className="flex h-16 items-center gap-4 px-4">
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="p-2 rounded-md hover:bg-gray-100"
              aria-label="Toggle menu"
            >
              <Menu className="h-5 w-5" />
            </button>

            <div className="font-semibold text-lg">BitPoint Admin</div>

            <div className="flex-1 flex items-center justify-end gap-4">
              <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
              <NotificationsBell notifications={notifications} />
              <UserMenu user={user} isAdmin={isAdmin} handleLogout={handleLogout} />
            </div>
          </div>
        </header>

        {/* Main Layout */}
        <div className="flex h-[calc(100vh-4rem)]">
          {/* Sidebar */}
          <aside 
            className={cn(
              "fixed inset-y-0 left-0 z-50 w-64 bg-white lg:static",
              "transform transition-transform duration-200 ease-in-out lg:transform-none",
              "border-r border-gray-200",
              "pt-16 lg:pt-0",
              sidebarOpen ? "translate-x-0" : "-translate-x-full",
              !sidebarOpen && !isMobile && "lg:w-20"
            )}
          >
            {/* Mobile Close Button */}
            {isMobile && (
              <button
                onClick={() => setSidebarOpen(false)}
                className="absolute top-4 right-4 p-2 rounded-md hover:bg-gray-100 lg:hidden"
              >
                <X className="h-5 w-5" />
              </button>
            )}

            {/* Navigation */}
            <nav className="px-3 py-4">
              <ul className="space-y-6">
                {getMenuItems().map((item, index) => (
                  item.type === 'section' ? (
                    <li key={index} className="space-y-2">
                      {(sidebarOpen || isMobile) && (
                        <div className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                          {item.title}
                        </div>
                      )}
                      <ul className="space-y-1">
                        {item.items.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <SidebarItem
                              icon={subItem.icon}
                              title={subItem.title}
                              to={subItem.to}
                              onClick={() => isMobile && setSidebarOpen(false)}
                            />
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li key={index}>
                      <SidebarItem
                        icon={item.icon}
                        title={item.title}
                        to={item.to}
                        onClick={() => isMobile && setSidebarOpen(false)}
                      />
                    </li>
                  )
                ))}
              </ul>
            </nav>
          </aside>

          {/* Main Content */}
          <main className="flex-1 overflow-y-auto">
            <div className="container mx-auto p-4 lg:p-6">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </SidebarContext.Provider>
  );
};

export default AdminLayout;