import api from './api';

export const dashboardAPI = {
  async getInitialData() {
    try {
      const response = await api.post('/method/eclbitpoint.api.dashboard.get_initial_data');
      console.log('Initial Data Response:', response);
      return response;
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
      throw error;
    }
  },

  async getDashboardData(params) {
    console.log('Raw params received:', params);

    // For Frappe, we'll send these as body parameters instead of query parameters
    const requestData = {
      media_profile: params.mediaProfile,
      from_date: params.fromDate,
      to_date: params.toDate,
      status: params.status || 'All'
    };

    console.log('Request data:', requestData);

    try {
      // Using POST instead of GET for Frappe methods
      const response = await api.post('/method/eclbitpoint.api.dashboard.get_dashboard_data', requestData);
      
      console.log('API Response:', {
        data: response,
      });

      return response;
    } catch (error) {
      console.error('API Error Details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: {
          url: error.config?.url,
          method: error.config?.method,
          data: error.config?.data,
          headers: error.config?.headers
        }
      });
      throw error;
    }
  },

  getPurchaseCodeTransactions: async ({
    purchaseCode,
    mediaProfile,
    fromDate,
    toDate,
    page = 1,
    pageSize = 10
  }) => {
    const requestData = {
      purchase_code: purchaseCode,
      media_profile: mediaProfile,
      from_date: fromDate,
      to_date: toDate,
      page,
      page_size: pageSize
    };

    console.log('Transaction request data:', requestData);

    try {
      // Using POST instead of GET
      const response = await api.post(
        '/method/eclbitpoint.api.dashboard.get_purchase_code_transactions', 
        requestData
      );
      
      console.log('Transaction response:', response);
      return response.message;
    } catch (error) {
      console.error('Transaction request failed:', error);
      throw error;
    }
  },
};

// Helper functions remain the same
export const helpers = {
  maskPhoneNumber: (phone) => {
    if (!phone) return '';
    const cleaned = phone.replace(/\D/g, '');
    if (cleaned.length < 8) return phone;
    return `${cleaned.slice(0, 3)}XXXX${cleaned.slice(-3)}`;
  },

  formatCurrency: (amount) => {
    if (typeof amount !== 'number') {
      console.warn('Invalid amount provided to formatCurrency:', amount);
      amount = 0;
    }
    return new Intl.NumberFormat('en-KE', {
      style: 'currency',
      currency: 'KES',
      minimumFractionDigits: 2
    }).format(amount);
  },

  validateDateRange: (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);
    return !isNaN(from.getTime()) && 
           !isNaN(to.getTime()) && 
           from <= to;
  }
};

export default dashboardAPI;