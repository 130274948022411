import { useState, useEffect } from 'react';
import { dashboardAPI } from '../services/dashboardService';

export const useDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({
    mediaProfile: '',
    fromDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    toDate: new Date().toISOString().split('T')[0],
    status: 'All'
  });

  const [userProfiles, setUserProfiles] = useState([]);
  const [initialized, setInitialized] = useState(false);

  // Load initial data and set default profile
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const response = await dashboardAPI.getInitialData();
        console.log('Initial Data Response:', response);

        const message = response?.message || {};
        if (message.status === 'success') {
          setUserProfiles(message.user_profiles);
          
          if (message.default_profile) {
            setFilters(prev => ({
              ...prev,
              mediaProfile: message.default_profile
            }));
          }
        }
      } catch (err) {
        console.error('Initial data error:', err);
        setError(err.message);
      } finally {
        setInitialized(true);
        setLoading(false);
      }
    };

    if (!initialized) {
      loadInitialData();
    }
  }, [initialized]);

  // Load dashboard data when filters change
  useEffect(() => {
    const loadDashboardData = async () => {
      if (!filters.mediaProfile) return;

      try {
        setLoading(true);
        console.log('Fetching with filters:', filters);
        
        const dashboardData = await dashboardAPI.getDashboardData({
          mediaProfile: filters.mediaProfile,
          fromDate: filters.fromDate,
          toDate: filters.toDate,
          status: filters.status
        });

        console.log('Dashboard Data:', dashboardData);

        if (dashboardData?.message) {
          setData(dashboardData.message);
        }
      } catch (err) {
        console.error('Dashboard data load error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (initialized) {
      loadDashboardData();
    }
  }, [filters, initialized]);

  const updateFilters = (newFilters) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters
    }));
  };

  return {
    loading,
    error,
    data,
    filters,
    userProfiles,
    updateFilters,
    initialized
  };
};
